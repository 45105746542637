var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-flow","title":'รายงานตัวเลือกสถานศึกษา ตำแหน่งครูผู้ช่วย รอบที่ : ' +
          _vm.periodassteachs.periodAssTeachTimes +
          ' / ' +
          _vm.periodassteachs.periodAssTeachYear}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headers,"items":_vm.manage_college_manpowers,"search":_vm.search,"group-by":"mcm_id_branch","item-class":_vm.row_classes,"disable-pagination":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
          var items = ref.items;
          var isOpen = ref.isOpen;
          var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"10"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_vm._v(" "+_vm._s(items[0].mcm_id_branch + " : " + items[0].name_branch)+" ")],1)]}},{key:"item.whoBooking",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.whoBooking)+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.mcm_status !== 'send' && item.mcm_status !== 'receipt')?_c('v-icon',{attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.manage_college_manpowerEdit(
                item.mcm_times,
                item.mcm_years,
                item.mcm_id_position
              )}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}},{key:"item.action_s",fn:function(ref){
              var item = ref.item;
return [(item.mcm_status !== 'send' && item.mcm_status !== 'receipt')?_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.manage_college_manpowerDelete(
                item.mcm_times,
                item.mcm_years,
                item.mcm_id_position
              )}}},[_vm._v(" mdi-delete ")]):_c('v-icon',{attrs:{"dark":"","color":"warning"}},[_vm._v("mdi-cube-send")])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }