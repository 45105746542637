<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-flow"
        :title="
          'รายงานตัวเลือกสถานศึกษา ตำแหน่งครูผู้ช่วย รอบที่ : ' +
            periodassteachs.periodAssTeachTimes +
            ' / ' +
            periodassteachs.periodAssTeachYear
        "
        class="px-5 py-3"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_college_manpowers"
          :search="search"
          group-by="mcm_id_branch"
          class="elevation-1"
          :item-class="row_classes"
          disable-pagination

        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="10">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].mcm_id_branch + " : " + items[0].name_branch }}
            </th>
          </template>

          <template v-slot:item.whoBooking="{ item }">
            {{ item.whoBooking }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.mcm_status !== 'send' && item.mcm_status !== 'receipt'"
              color="warning"
              @click.stop="
                manage_college_manpowerEdit(
                  item.mcm_times,
                  item.mcm_years,
                  item.mcm_id_position
                )
              "
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              v-if="item.mcm_status !== 'send' && item.mcm_status !== 'receipt'"
              color="red"
              @click.stop="
                manage_college_manpowerDelete(
                  item.mcm_times,
                  item.mcm_years,
                  item.mcm_id_position
                )
              "
            >
              mdi-delete
            </v-icon>
            <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import { async } from 'q';
export default {
  name: "HRvecManageAssistantTeacher",

  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,

      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_college_manpowers: [],
      addmanage_college_manpower: {},
      editmanage_college_manpower: {},
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "ภาค", align: "left", value: "region_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่ง", align: "left", value: "mcm_id_position" },
        { text: "สถานะ", align: "left", value: "whoBooking" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      manage_college_manpowerstatus: [],

      periodassteachs: []
    };
  },

  async mounted() {
    await this.showLocaleTime();
    await this.periodassteachQuery();
    

    if (this.periodassteachs.periodAssTeachEnable != "1")
    {
      Swal.fire({
          icon: "error",
          title: "ยังไม่มีกำหนดการ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/");
          }
        });
    }else{
      await this.manage_college_manpowerQueryAll();
    }
  

  },

  methods: {
   async showLocaleTime() { 
      setInterval(async() => {
        await this.manage_college_manpowerQueryAll();
      }, 3000);     
    },


    async periodassteachQuery() {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnablePro: "1"
      });
      this.periodassteachs = result.data;

     

    },

    async sweetAlertLoading() {
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then(result => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
     
        }
      });
    },

    async manage_college_manpowerQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_college_manpower.php", {
          ApiKey: this.ApiKey,
          mcm_times: this.periodassteachs.periodAssTeachTimes,
          mcm_years: this.periodassteachs.periodAssTeachYear,
          report_selete:"ok"
        })
        .finally(() => (this.loading = false));
      this.manage_college_manpowers = result.data;
    },
    row_classes(item) {
      let whoBooking = String(item.whoBooking)
      if (whoBooking.length > 4) {
        return "green";
      }
    }
  },

  computed: {
    color() {
      return "light-blue-darken-4";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>

<style scoped>
.hide-idcard-number {
  display: inline-block;
  font-family: monospace;
  position: relative;
}

.hide-idcard-number::after {
  content: "XXXXXXXX";
  background: white;
  position: absolute;
  left: 0;
}
</style>
